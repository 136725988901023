import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import HomeContent from './components/HomeContent/HomeContent';
import ProjectList from './components/ProjectContent/ProjectList/ProjectList';
import ProjectLunarContent from './components/ProjectContent/Lunar/ProjectLunarContent';
import ProjectGan from './components/ProjectContent/Gan/ProjectGan';
import ProjectCorporateIntelligence from './components/ProjectContent/CorporateIntelligence/ProjectCorporateIntelligence';
import ProjectOrganicShapes from './components/ProjectContent/OrganicShapes/ProjectOrganicShapes';
import ProjectDrawingAssistantContent from './components/ProjectContent/DrawingAssistant/ProjectDrawingAssistantContent';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div>
        <Sidebar />
        <div>
          <Routes>
            <Route path="/" element={<HomeContent />} />
            <Route path="/projects_ProjectList" element={<ProjectList />} />
            <Route path="/projects_Lunar" element={<ProjectLunarContent />} />
            <Route path="/projects_Gan" element={<ProjectGan />} />
            <Route path="/projects_CoporateIntelligence" element={<ProjectCorporateIntelligence />} />
            <Route path="/projects_OrganicShapes" element={<ProjectOrganicShapes />} />
            <Route path="/projects_DrawingAssistant" element={<ProjectDrawingAssistantContent />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
