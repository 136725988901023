import React from 'react';

import InformationSticky from '../../InformationSticky';
import Divider from '../../Divider';
import Title from '../../Title';
import Subtitle from '../../Subtitle';

const ProjectList = () => {
    return (
        <>
            <InformationSticky location="Berlin (DE)" date="Dec 20, 2024" />
            <div className="content">
                <div className="container">
                    <Title text="Project List"/>
                    <Divider/>
                    <p>Effenbergplatz BPL 1+2, 1220 Vienna, Austria | Construction completed in 2023, GFA 8.834 m² | As part of <a href="https://www.wg-a.com/">WGA Deutschland</a></p>
                    <Subtitle text="New construction of a residential complex with 119 units and a local supermarket"/>
                    
                    <Divider/>
                    <p>Kirchgrabenstraße 15-20, 65795 Hattersheim am Main, Germany | Construction completed in 2022, GFA 8.931 m² | As part of AO Berlin</p>
                    <Subtitle text="Refurbishment of an industrial monument as a residential complex with 69 units"/>
                    
                    <Divider/>
                    <p>Triester Straße 91, 1100 Vienna, Austria | Construction completed in 2021, GFA 19.725 m² | As part of <a href="https://hd-architekten.at/">HD Architekten</a></p>
                    <Subtitle text="New construction of a residential complex with 136 units"/>
                    
                    <Divider/>
                    <p>Anna-Flügge-Straße 2-3, 14467 Potsdam, Germany | Construction completion scheduled for 2027, GFA 2.561 m² | As part of <a href="https://www.wg-a.com/">WGA Deutschland</a></p>
                    <Subtitle text="New construction of a student housing with 54 residential units"/>
                    
                    <Divider/>
                    <p>Conrad-Blenkle-Straße 36, 10407 Berlin, Germany | Construction completion scheduled for 2028, GFA 11.476 m² | As part of <a href="https://www.wg-a.com/">WGA Deutschland</a></p>
                    <Subtitle text="New construction of a hotel with 258 rooms and a local supermarket"/>

                    <Divider/>
                    <p>Fasanenstraße 9-10, 10623 Berlin, Germany | Construction completed in 2028, GFA 9.813 m² | As part of AO Berlin</p>
                    <Subtitle text="Refurbishment of a hotel with 122 rooms and public catering areas"/>

                    <Divider/>
                    <p>Adolf-Kolping-Straße 10, 80336 Munich, Germany | Construction completion scheduled for 2026, GFA 29.374 m² | As part of <a href="https://www.wg-a.com/">WGA Deutschland</a></p>
                    <Subtitle text="New construction of an office complex with 550 parking spaces"/>

                    <Divider/>
                    <p>LabCampus, Munich-Airport, Germany | Construction completion scheduled for 2028, GFA 12.596 m² | As part of <a href="https://www.wg-a.com/">WGA Deutschland</a></p>
                    <Subtitle text="New construction of 230 serviced-apartments with public catering areas"/>

                    <Divider/>
                    <p>Gotenstraße 52-54, 10829 Berlin, Germany | Construction completed in 2020, GFA 1.426 m² | As part of AO Berlin</p>
                    <Subtitle text="New construction of a day care centre for a residential complex with 221 units"/>
                </div>
            </div>
        </>
    );
};

export default ProjectList;