import React from 'react';

import Image from '../Image';
import imagePathA from './NewsA.jpg';
import imagePathB from './NewsB.jpg';
import imagePathC from './NewsC.jpg';
import imagePathD from './NewsD.jpg';

const NewsSticky = () => {

    return (
        <div className="information-sticky">
            <div className="container">
            <Image src={imagePathA} />
            <Image src={imagePathB} />
            <Image src={imagePathC} />
            <Image src={imagePathD} />
            </div>
        </div>
    );
};

export default NewsSticky;