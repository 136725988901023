import React from 'react';

import InformationSticky from '../InformationSticky';
import NewsSticky from './NewsSticky';
import Divider from '../Divider';
import QuoteDivider from '../QuoteDivider';
import Title from '../Title';
import Subtitle from '../Subtitle';
import QuoteSubtitle from '../QuoteSubtitle';
import MenuItem from '../MenuItem';
import QuoteMenuItem from '../QuoteMenuItem';

import P5FrameLunar from '../ProjectContent/Lunar/P5FrameLunar';

const HomeContent = () => {
    return (
        <>
            <NewsSticky/>
            <div className="content">
                <div className="container">
                    <section id="about">
                        <Title text="Hello, my name is Boy d'Hont"/>
                        <p>I am an architect, involved in large-scale residential, hotel and office design projects, hacking and tutoring BIM-tools on the way. Official member of the Chamber of Architects Berlin.</p>
                        <Divider/>
                        <P5FrameLunar/>
                    </section>
                    <section id="projects">
                        <Divider/>
                        <Subtitle text="Architecture and BIM-Consulting"/>
                        <MenuItem to="/projects_ProjectList" text="Project List" />
                        <QuoteDivider/>
                        <QuoteSubtitle text="Digital Products and Case-Studies"/>
                        <QuoteMenuItem to="/projects_Lunar" text="Lunar, the Library for Parametric Design" />
                        <QuoteMenuItem to="/" text="BetterCorrectFast, simple Generation of BCF-Issues" />
                        <QuoteMenuItem to="/projects_OrganicShapes" text="Organic Shapes for the Masses" />
                    </section>
                    <section id="contact">
                        <Divider/>
                        <Subtitle text="Contact"/>
                        <p>I am always open for new ideas and collaborations. <a href="mailto: contact@bdhont.net">Send an Email</a>.</p>
                    </section>
                </div>
            </div>
        </>
    );
};

export default HomeContent;