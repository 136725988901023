import React from 'react';

import InformationSticky from '../../InformationSticky';
import Divider from '../../Divider';
import QuoteDivider from '../../QuoteDivider';
import Title from '../../Title';
import Quote from '../../Quote';
import Image from '../../Image';
import ContextTitle from '../../ContextTitle';

import imagePathA from './Beach.jpg';

const ProjectGan = () => {
    return (
        <>
            <InformationSticky location="Berlin (DE)" date="Nov 25, 2019" />
            <div className="content">
                <div className="container">
                    <Title text="GAN in the Design Office"/>
                    <p>AI machine learning technologies evolve in a fast pace. Generative Advasarial Network (GAN) images, which aren’t just visual representations of neural networks, are generative graphics. Using computer graphics and machine learnings, they
                        can create real-looking visuals of non-existing objects. The invention was originally developed by Ian Goodfellow and later popularized by large tech companies.</p>
                    <p>The AI models aid architects in the communication of concepts under a reduced timespan, saving the effort to model landscape elements with traditional tools.</p>
                    <QuoteDivider/>
                    <Quote text="The science behind the machine learning still develops rapidly, resulting in significant improvements each year. In its current state, GAN technology is ready to become part of the architect’s workflow."/>
                    <Image src={imagePathA} />
                    <Divider/>
                    <ContextTitle text="Context"/>
                    <p>The presented images are generated using <a href="http://nvidia-research-mingyuliu.com/gaugan/">NVIDIA’s GauGAN model</a>, that turns simple stroke brushes into complex landscape visuals. Then the raw image is treated with
                        simple colour correction. Single pre-photographed content is added to the collage to give the visual character, to let the GAN image stand out from its generated siblings.</p>
                </div>
            </div>
        </>
    );
};

export default ProjectGan;