import React from 'react';

import InformationSticky from '../../InformationSticky';
import Divider from '../../Divider';
import QuoteDivider from '../../QuoteDivider';
import Title from '../../Title';
import Quote from '../../Quote';
import Image from '../../Image';
import ContextTitle from '../../ContextTitle';

import imagePathA from './Print.jpg';
import imagePathB from './Presentation.jpg';
import imagePathC from './Stretch.jpg';

const ProjectOrganicShapes = () => {
    return (
        <>
            <InformationSticky location="Bratislava (SL)" date="Mai 28, 2018" />
            <div className="content">
                <div className="container">
                    <Title text="Organic Shapes for the Masses" />
                    <p>The hackaton of the <a href=" https://2018.sensorium.is/ " >Sensorium Festival 2018</a> seeks to find harmony between the contrasting worlds of dance performance and digital coding. In the spirit of this task, an installation
                        came to life in which visitors gained the opportunity to generate complex three-dimensional shapes using their own body movement.</p>
                    <QuoteDivider />
                    <Quote text="The prototype reads movement from webcam footage using frame-differencing, building up a point cloud in the process. This data becomes a solid shape with the use of ball-pivot algorithms. The generator reads the location, intensity and curvature of the movement." />
                    <Image src={imagePathA} />
                    <Image src={imagePathB} />
                    <Image src={imagePathC} />
                    <Divider/>
                    <ContextTitle text="Context"/>
                    <p>The project was designed and build during the 4-day of the <a>Choreographic Coding Lab 2018</a> and displayed at the first official day of <a href="https://2018.sensorium.is/ " >Sensorium Festival 2018</a>. The input data is generated in collaboration with choreographer and visual artist <a href="https://www.sungeunji.com/ ">Eunji Sung.</a></p>
                </div>
            </div>
        </>
    );
};

export default ProjectOrganicShapes;