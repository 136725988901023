import React from 'react';
import { NavLink } from 'react-router-dom';

const QuoteMenuItem = ({ to, text }) => {
    return (
        <NavLink to={to} className="animated" activeclassname="active">
            <h1 className="h1-quote">{text}</h1>
        </NavLink>
    );
};

export default QuoteMenuItem;