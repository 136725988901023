import React from 'react';

const Sidebar = () => {
    return (
        <div className="sidebar-sticky">
            <div className="navigation-bar">
                <div className="container">
                    <h1 className="logo-text"><a className="logo-text animated" href="/">Studio Boy d'Hont</a></h1>
                    <p className="footer-text">| Architecture, BIM and Digital Mentoring <a className="footer-text-blink">_</a></p>
                    <nav>
                        <a className="animated" href="/">
                            <p className="menu-text">About</p>
                        </a>
                        <a className="animated" href="/">
                            <p className="menu-text">Projects</p>
                        </a>
                        <a className="animated" href="/">
                            <p className="menu-text">Contact</p>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;