import React from 'react';

import InformationSticky from '../../InformationSticky';
import Divider from '../../Divider';
import QuoteDivider from '../../QuoteDivider';
import Title from '../../Title';
import Quote from '../../Quote';
import ContextTitle from '../../ContextTitle';

import P5FrameLunar from './P5FrameLunar';

const ProjectLunarContent = () => {
    return (
        <>
            <InformationSticky location="Berlin (DE)" date="Feb 03, 2018" />
            <div className="content">
                <div className="container">
                    <Title text="Lunar, the Library for Parametric Design"/>
                    <p>Lunar assists in the process of understanding the design nodes that hide behind the interfaces of graphical programming languages. The geometric methods, as presented in this book, live separate from existing software solutions to allow
                        a flexible translation between the digital design solutions you already know and the ones that still need to be invented.</p>
                    <p>In a professional context, designers don't always have the freedom to choose their design tools. It is necessary to speak an algorithmic vocabulary that translates to these crude virtual instruments.</p>
                    <QuoteDivider/>
                    <Quote text="The book takes you along the core foundations of 3D vectors mathematics, mesh development and pattern generation. It provides the necessary vocabulary to boost your computational design journey, independent of the tools available."/>
                    <P5FrameLunar/>
                    <Divider/>
                    <ContextTitle text="Context"/>
                    <p>Lunar is available as an <a href="https://www.amazon.com/Lunar-essential-algorithms-computational-design-ebook/dp/B0852PXQJZ/ref=sr_1_1?dchild=1&keywords=lunar%3A+50+essential+algorithms&qid=1586715622&sr=8-1">ebook</a> and as an extention library for the Creative Coding frameworks <a href="https://processing.org/reference/libraries/ ">Processing 3</a> and <a href="https://p5js.org/">P5js</a>. Additional libraries for the Creative Coding framework <a href="https://openrndr.org/ ">OpenRNDR</a> are currently in development.</p>
                </div>
            </div >
        </>
    );
};

export default ProjectLunarContent;