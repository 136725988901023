import React from 'react';
import InformationSticky from '../../InformationSticky';
import Divider from '../../Divider';
import QuoteDivider from '../../QuoteDivider';
import Title from '../../Title';
import Quote from '../../Quote';

const ProjectDrawingAssistantContent = () => {
    return (
        <>
            <InformationSticky location="Vienna (AT)" date="June 21, 2019" />
            <div className="content">
                <div className="container">
                    <Title text="The Artificial Drawing Assistant"/>
                    <p>In contrast to instruments that orient towards hand-drawing, Computer Aided Design forces the user to train unnatural and secondary techniques and knowledge. The use of a foreign drawing software comes with new workflows and skills, skills
                        that tend to fail to conglomerate with the competences required to produce a clear drawing. In 2007, user interface design turned for the better with the introduction of the ribbon layout: multiple complex functions group in smaller
                        chunks, granting the user the possibility to quickly shift beteen simple and complex commands. Though, new deep learning AI technologies can improve on this strategy, by linking a workflow of unnatural software functions to a humane
                        expression.
                    </p>
                    <p>CAD drawing tasks fall under one of three categories: solving design problems, generation of communication documents and quality maintenance of the CAD/BIM model. The generation of communication documents is the most desirable to automate,
                        as CAD/BIM related automation is heavily dependent on the software and humans can still focus on design tasks. The Artificial Drawing Assistant can help users that are familiar with older Computer Aided Design systems with their transition
                        to contemporary drawing technologies, as the language of the practice will remain stable.</p>
                    <QuoteDivider/>
                    <Quote text="The Artificial Drawing Assistant forms the first implementation of this technique. She serves as an intelligent upgrade of the well-known CAD command bar. The user controls the drawing process with additional voice control or traditional keyboard input."/>
                    <iframe width="100%" margin-top="20px" margin-bottom="20px" allow="microphone;" height="500" src="https://console.dialogflow.com/api-client/demo/embedded/8b9c5538-b4a9-4740-8c60-caf7ea3d45c6"></iframe>
                    <p>
                        Example instructions for <a>Generation</a>: <br /> &emsp; "Generate a new section with the plan name Section A-A' and number 1070_55_7667"<br /> &emsp; "Create a temporary floor plan with information about the current selection"<br /> &emsp;
                        "Produce all views for the detailed design phase"

                        <br /> <br /> Example instructions for <a>Annotation</a>:<br /> &emsp; "Annotate all doors in the current design"<br /> &emsp; "Complement all visible tags with this parameter"<br /> &emsp; "Hide this information from the current annotation"

                        <br /><br /> Example instructions for <a>Comparison</a>:<br /> &emsp; "Show me the differences between this drawing and this older drawing" <br /> &emsp; "Compare the data from this list with this other list" <br /> &emsp; "What is the difference
                        between my drawing and this autocad document?"

                        <br /><br /> Example instructions for <a>Synchronization</a>:<br /> &emsp; "Synchronise the list with an external list" <br /> &emsp; "Copy the contents of this drawing to another drawing" <br /> &emsp; "Make sure that the active list is the
                        same as the other list"
                    </p>
                </div>
            </div >
        </>
    );
};

export default ProjectDrawingAssistantContent;